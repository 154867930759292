import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import {Switch, Route} from 'react-router'
import {useCookies} from 'react-cookie'
import Home from './screens/Home'
import { prices } from "./prices"
import Loading from './components/Loading'
import 'bootstrap/dist/css/bootstrap.min.css';

const Styles = styled.div`

.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}
  .navbar{
    background-color: black;
  }
  .card--container{
    transition: 1.5s;
    padding: 30px;
    background-color: white;
  }
  .prices--row{
    margin-top: 12px;
  }
  .card--container:hover{
    transition: 1.5s;
    color: white;
    -webkit-box-shadow: 10px 10px 45px -5px rgba(0,0,0,0.75);
    -moz-box-shadow: 10px 10px 45px -5px rgba(0,0,0,0.75);
    box-shadow: 10px 10px 45px -5px rgba(0,0,0,0.75);
  }
  .footer--container{
    height: 75px;
    display: flex;
    background-color: black;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .worker--container{
    min-height: 175px;
    max-height: 325px;
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid lightgrey;
    border-radius: 12px;
  }
  .container--fullpage-center{
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .container--fullpage{
    min-height: 100vh;
    display: flex;
  }
  .bg-black{
    background-color: black;
  }
  .lighter{
    font-weight: lighter;
  }
  .black{
    color: black;
  }
  .prices-card--button{
    background-color: white;
  }
  .card--container{
    background-color: white;
    min-height: 225px;
    margin: 12px;
    border-radius: 12px;
  }
  .card--description{
    transition: 0.75s;
    opacity: 0;
  }
  .card--description:hover{
    transition: 0.75s;
    opacity: 1;
  }
`

function App() {
  
  const [isLoading, setIsLoading] = useState(true)
  // const [isLogged, setIsLogged] = useState(true)
  // const [userID, setUserID] = useState(null)
  // const [cookies, useCookies, removeCookies] = useCookies(['session'])
  
  // async function checkIfLoggedIn(){
  //   cookie.get('user_id').then(id => {
  //     if(id){
  //       setIsLogged(true)
  //     }else{
  //       setIsLogged(false)
  //     }
  //     setIsLoading(false)
  //   })
  // }

  const imageArray = [
    `${process.env.PUBLIC_URL}/mystylelogo.webp`,
    "https://assets.website-files.com/5e51c674258ffe10d286d30a/5e535eb7550b76782df9e820_peep-sitting-7.svg",
    "https://images.yclients.com/masters/norm/9/9c/9cc1e603132b07c_20200201141536.png",
    "https://images.yclients.com/masters/norm/9/98/9882e8d9ca14f3e_20200205233200.png",
    `${process.env.PUBLIC_URL}/yulia.jpeg`,
    "https://images.yclients.com/masters/norm/3/35/35be2743a4546c3_20200201141559.png",
    `${process.env.PUBLIC_URL}/phone.png`,
    `${process.env.PUBLIC_URL}/1.jpeg`

  ]

  function getPrices(){
    imageArray.forEach((image) => {
      const img = new Image();
      img.src = image;
    });
    setTimeout(() => {
      setIsLoading(false);
    })
  }

  if(isLoading){
    getPrices()
    return (
      <Styles>
        <Loading/>
      </Styles>
    )
  }else{
    return(
      <Styles>
        <Home prices={ prices }/>
      </Styles>
    )
  }
}

export default App;
