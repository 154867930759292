module.exports = {
    prices: {
        men: [
            {
                "name": "Стрижка машинкой одна насадка или без",
                "priceFrom": "400",
                "priceTo": "400"
            },
            {
                "name": "Стрижка машинкой 2 насадки ",
                "priceFrom": "600",
                "priceTo": "600"
            },
            {
                "name": "Стрижка Модельная",
                "priceFrom": "800",
                "priceTo": "1000"
            },
            {
                "name": "Оформление бороды машинками",
                "priceFrom": "400",
                "priceTo": "800"
            },
            {
                "name": "Оформление бороды опасной бритвой",
                "priceFrom": "1000",
                "priceTo": "1000"
            },
            {
                "name": "Бритьё головы",
                "priceFrom": "1000",
                "priceTo": "1000"
            },
            {
                "name": "Мытьё головы без укладки",
                "priceFrom": "400",
                "priceTo": "400"
            },
            {
                "name": "Мытьё головы с укладкой",
                "priceFrom": "800",
                "priceTo": "800"
            },
            {
                "name": "Комбо Стрижка + оформление бороды",
                "priceFrom": "1400",
                "priceTo": "1600"
            },
            {
                "name": "Камуфляж седины",
                "priceFrom": "1000",
                "priceTo": "1500"
            }
        ],
        sale: [
            {
                "name": "Мама + дочь ",
                "priceFrom": "1200",
                "priceTo": "2000"
            },
            {
                "name": "Папа + сын\t(ребенок не старше 12 лет) ",
                "priceFrom": "1000",
                "priceTo": "1000"
            },
            {
                "name": "Стрижка для пенсионеров (акция действует в будние дни с 10 до 13 часов)",
                "priceFrom": "400",
                "priceTo": "400"
            },
            {
                "name": "Стрижка и окрашивание для пенсионеров в один тон (акция действует в будние дни с 10 до 13 часов цена указана уже с учетом материала и варьируется с учетом длинны и густоты волос)",
                "priceFrom": "1000",
                "priceTo": "2500"
            }
        ],
        students: [
            {
                "name": "Студентам (мужская)  ",
                "priceFrom": "600",
                "priceTo": "600"
            },
            {
                "name": "Студентам (женская)  ",
                "priceFrom": "600",
                "priceTo": "2000"
            },
            {
                "name": "Детская стрижка до 10 лет ",
                "priceFrom": "400",
                "priceTo": "400"
            },
            {
                "name": "Детская стрижка с 10 до 16 лет ",
                "priceFrom": "600",
                "priceTo": "800"
            }
        ],
        care: [
            {
                "name": "Умная пена DEMI (цена зависит от длинны, густоты волос, расхода материала) ",
                "priceFrom": "1500",
                "priceTo": "2000"
            },
            {
                "name": "Лечебный уход DEMI Composio EQ ",
                "priceFrom": "2000",
                "priceTo": "4000"
            },
            {
                "name": "Маска BBone Picasso Insta BTX ",
                "priceFrom": "1000",
                "priceTo": "2500"
            }
        ],
        botox: [
            {
                "name": "«Ботокс» Aqua BBone  ( цена указана без учета материала) ",
                "priceFrom": "1500",
                "priceTo": "3000"
            },
            {
                "name": "Выпрямляющий шампунь High Liss BBone ",
                "priceFrom": "1500",
                "priceTo": "4000"
            }
        ],
        women: [
            {
                name: "Женская стрижка короткие волосы",
                priceFrom: "800",
                priceTo: "1500" 
            },
            {
                name: "Женская стрижка средний длинны волосы",
                priceFrom: "1000",
                priceTo: "2000" 
            },
            {
                name: "Женская стрижка на длинные волосы ",
                priceFrom: "1500",
                priceTo: "2500" 
            },
            {
                name: "Стрижка чёлки ",
                priceFrom: "200",
                priceTo: "400" 
            },
            {
                name: "Стрижка концов с полировкой по длине",
                priceFrom: "800",
                priceTo: "1500" 
            }
        ],
        dying: [
            {
                name: "Окрашивание коротких волос в один тон",
                priceFrom: "1000",
                priceTo: "2000" 
            },
            {
                name: "Окрашивание средних волос в один тон",
                priceFrom: "1500",
                priceTo: "2500" 
            },
            {
                name: "Окрашивание длинных волос",
                priceFrom: "2000",
                priceTo: "3500" 
            },
            {
                name: "Окрашивание корней",
                priceFrom: "1000",
                priceTo: "2500" 
            },
            {
                name: "Сложное окрашивание волос",
                priceFrom: "2500"
            },
            {
                name: "Мелирование в разных техниках",
                priceFrom: "1500",
                priceTo: "5000" 
            },
            {
                name: "Мытье головы без укладки",
                priceFrom: "400",
                priceTo: "800" 
            },
            {
                name: "Укладка",
                priceFrom: "600",
                priceTo: "2000" 
            },
            {
                name: "Вечерняя причёска",
                priceFrom: "1000",
                priceTo: "3000" 
            },
        ],
        manicure: [
            {
                name: "Маникюр женский",
                priceFrom: "1000",
                priceTo: "1000" 
            },
            {
                name: "Комбо (Снятие+маникюр+покрытие однотон",
                priceFrom: "2000",
                priceTo: "2000" 
            },
            {
                name: "Маникюр мужской",
                priceFrom: "1000",
                priceTo: "1000" 
            },
            {
                name: "Покрытие лак",
                priceFrom: "400",
                priceTo: "400" 
            },
            {
                name: "Снятие лака",
                priceFrom: "200",
                priceTo: "200" 
            },
            {
                name: "Детский маникюр",
                priceFrom: "500",
                priceTo: "500" 
            },
            {
                name: "Снятие без покрытия",
                priceFrom: "500",
                priceTo: "500" 
            },
            {
                name: "Ремонт ногтя",
                priceFrom: "150",
                priceTo: "250" 
            },
            {
                name: "Укрепление акригелем",
                priceFrom: "700",
                priceTo: "700" 
            },
            {
                name: "Укрепление акриловой пудрой",
                priceFrom: "500",
                priceTo: "500" 
            },
            {
                name: "Дизайн (1 ноготь)",
                priceFrom: "70",
                priceTo: "500" 
            },
            {
                name: "SPA-уход + парафинотерапия",
                priceFrom: "900",
                priceTo: "900" 
            },
        ],
        pedicure: [
            {
                name: "Комплексная обработка ног",
                priceFrom: "2300",
                priceTo: "2500" 
            },
            {
                name: "Комбо 1 (Снятие+ обработка только пальчии+покрытие однотон)",
                priceFrom: "2500",
                priceTo: "2500" 
            },
            {
                name: "Комбо 2 (Снятие+педикюр с обработкой стопы+покрытие)",
                priceFrom: "2850",
                priceTo: "2850" 
            },
            {
                name: "Обработка пальцев ног",
                priceFrom: "1700",
                priceTo: "1700" 
            },
            {
                name: "Массаж ног",
                priceFrom: "700",
                priceTo: "700" 
            },
        ],
        brows: [
            {
                name: "Коррекция бровей",
                priceFrom: "450",
                priceTo: "450" 
            },
            {
                name: "Окрашивание бровей",
                priceFrom: "400",
                priceTo: "400" 
            },
            {
                name: "Окрашивание ресниц",
                priceFrom: "450",
                priceTo: "450" 
            }
        ],
        wax: [
            {
                name: "Голень",
                priceFrom: "800",
                priceTo: "800"  
            },
            {
                name: "Ноги полностью",
                priceFrom: "1300",
                priceTo: "1300"  
            },
            {
                name: "Плечо по локоть",
                priceFrom: "500",
                priceTo: "500"  
            },
            {
                name: "Рука полностью",
                priceFrom: "700",
                priceTo: "700"  
            },
            {
                name: "Подмышечные впадины",
                priceFrom: "400",
                priceTo: "400"  
            },
            {
                name: "Бикини классика",
                priceFrom: "800",
                priceTo: "800"  
            },
            {
                name: "Бикини среднее",
                priceFrom: "1000",
                priceTo: "1000"  
            },
            {
                name: "Бикини глубокое",
                priceFrom: "1300",
                priceTo: "1300"  
            },
            {
                name: "Монобровь",
                priceFrom: "300",
                priceTo: "300"  
            },
            {
                name: "Врехняя губа",
                priceFrom: "400",
                priceTo: "400"  
            }
        ],
        podology: [
            {
                name: "Выведение угла вросшего ногтя + перевязка",
                priceFrom: "700",
                priceTo: "1500"  
            },
            {
                name: "Обработка стержневых мозолей/ подногтевых мозолей/ гиперкератоза / трещин",
                priceFrom: "500",
                priceTo: "1000"  
            },
            {
                name: "Установка тампонады",
                priceFrom: "100",
                priceTo: "200"  
            },
            {
                name: "SPA педикюр",
                priceFrom: "2800",
                priceTo: "2800"  
            },
            {
                name: "Парафинотерапия ног",
                priceFrom: "900",
                priceTo: "900"  
            },
            {
                name: "Обработка грибковых поражений/ различных видов дистрофий (онихолизиса, онихогрифоза)",
                priceFrom: "500",
                priceTo: "2000"  
            },
            {
                name: "Установка корректирующей системы (титановая нить)",
                priceFrom: "2700",
                priceTo: "2700"  
            },
            {
                name: "Окклюзионная повязка на трещины",
                priceFrom: "500",
                priceTo: "700"  
            },
            {
                name: "Снятие покрытия",
                priceFrom: "300",
                priceTo: "300"  
            },
            {
                name: "Разгрузка",
                priceFrom: "250",
                priceTo: "500"  
            },
            {
                name: "Консультация",
                priceFrom: "500",
                priceTo: "500"  
            },
        ]
    } 
}