import React, {useState} from 'react'
import Fade from 'react-reveal/Fade'

export class PricesContainer extends React.Component{
    constructor(props){
        super()
    }

    render(props){
        return(
            <>
                <div className="card--container">
                    <h3 style={{color: 'black'}}>Мужской зал</h3>
                    <p style={{color: 'black', fontWeight: 'lighter'}}>Какие-то вот услуги, какое-то вот описание</p>
                </div>
            </>
        )
    }
}

export default PricesContainer