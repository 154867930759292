import React, {useState} from 'react'
import {Modal, Row, Col, Button} from 'react-bootstrap'
import PricesContainer from './PricesContainer'

export class PricesOverlay extends React.Component{
    constructor(props){
        super()
        this.state = {
            isVisible: false,
        }
    }
    render(props){
        return(
            <>
                <Button style={{backgroundColor: 'white', borderColor: 'black', color: 'black'}} variant="primary" onClick={() => this.setState({isVisible: true})}>
                    Посмотреть прайс
                </Button>
                <Modal
                    show={this.state.isVisible}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    >
                    <Modal.Header>
                        <Modal.Title id="contained-modal-title-vcenter">
                            {this.props.categoryName}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.props.prices.map(item => {
                                return(
                                    <Row className="prices--row" style={{minHeight: 50, padding: 12}}>
                                        <Col>
                                            <h5 style={{fontWeight: 'lighter'}}>{item.name}</h5>
                                        </Col>
                                        <Col>
                                            <h5>
                                                {item.priceFrom == item.priceTo && (item.priceFrom + ' руб.')}
                                                {typeof item.priceTo === 'undefined' && 'от ' + item.priceFrom + ' руб.'}
                                                {(item.priceTo != 0 && item.priceTo != null && item.priceFrom != item.priceTo) && (item.priceFrom + '-' + item.priceTo + ' руб.')}
                                            </h5>
                                        </Col>
                                    </Row>
                                )
                            })}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button style={{backgroundColor: 'white', borderColor: 'black', color: 'black'}} onClick={() => this.setState({isVisible: false})}>Закрыть</Button>
                    </Modal.Footer>
                    </Modal>
            </>
        )
    }
}

export default PricesOverlay