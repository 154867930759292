import Fade from 'react-reveal/Fade'
import PricesOverlay from '../components/PricesOverlay'
import { isMobile } from 'react-device-detect'
import {Container, Button, Image, Carousel, Navbar, Nav} from 'react-bootstrap'
import {Col, Row} from 'bootstrap-4-react'
export const Home = (props) => {
    return(
        <>
        <Navbar bg="light" sticky="top" collapseOnSelect expand="md">
            <Navbar.Brand href="#home" style={{borderWidth: 10, borderColor: 'black'}}>
                <img src={`${process.env.PUBLIC_URL}/mystylelogo.webp`} style={{height: 35, width: 62, padding: 1, backgroundColor: 'black'}}/>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
                <Nav>
                            <Nav.Link href="#about" style={{color: 'black'}}>О нас</Nav.Link>
                            <Nav.Link href="#team" style={{color: 'black'}}>Команда</Nav.Link>
                            <Nav.Link href="#price" style={{color: 'black'}}>Прайс</Nav.Link>
                            <Nav.Link href="https://shop.mystylespb.ru" style={{color: 'black'}}>Магазин</Nav.Link>
                            <Nav.Link href="#contacts" style={{color: 'black'}}>Контакты</Nav.Link>
                            <Nav.Link href="https://instagram.com/m_style_spb" style={{color: 'black'}}>Инстаграм</Nav.Link>
                            <Nav.Link href="https://vk.com/m_style_spb" style={{color: 'black'}}>Вконтакте</Nav.Link>
                            <Nav.Link href="https://www.facebook.com/mystylespb21" style={{color: 'black'}}>Фейсбук</Nav.Link>
                </Nav>
            </Navbar.Collapse>
            </Navbar>
            <Container fluid className="container--fullpage-center bg-black" style={{background: `url(${process.env.PUBLIC_URL + '1.jpeg'})`, backgroundAttachment: 'fixed', backgroundPosition: 'center', backgroundSize: 'cover'}}>
                        <div>
                                <div style={{backgroundColor: 'rgba(0,0,0,0.6)', padding: 30}}>
                                    <h1 class="display-3" style={{color: 'white'}}>МОЙ СТИЛЬ</h1>
                                    <div style={{marginTop: '13px', height: '3px', backgroundColor: 'white'}}/>
                                    <h4 className="lighter" style={{color: 'white', marginTop: 13}}>Услуги от настоящих профессионалов.</h4>
                                </div>
                                <br/>
                        </div>
            </Container>
            <Container fluid id="about" style={{display: 'flex',flexDirection: 'column', marginTop: 50, marginBottom: 50}}>
                <Row>
                    <Col lg="6" md="6" xs="12" sm="12" style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}> 
                        <div style={{wordBreak: 'break-all', width: '85%'}}>
                            <h1 className="5 black">КТО МЫ?</h1>
                            <h4 className="lighter black" style={{wordBreak: "break-word"}}>Мой Стиль - команда профессионалов по оказанию услуг в сфере красоты, креативная семья, где каждый посетитель может чувствовать себя как дома. Нашей главной задачей является вызывать у Вас улыбку от выполненной нами работы.</h4>
                        </div>
                    </Col>
                    <Col lg="6" md="6" xs="12" sm="12" style={{textAlign: 'center',display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                        <Image style={isMobile ? ({maxWidth: 200, alignSelf: 'center', marginTop: 30}):({maxWidth: 300, alignSelf: 'center'})}src="https://assets.website-files.com/5e51c674258ffe10d286d30a/5e535eb7550b76782df9e820_peep-sitting-7.svg"/>
                    </Col>
                </Row>
            </Container>
            <Container id="price" fluid className="container--fullpage" style={{display: 'flex', minHeight: '100vh', justifyContent: 'center', alignItems: 'center',padding: 30, backgroundColor: '#000000',
backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='199' viewBox='0 0 100 199'%3E%3Cg fill='%23ffffff' fill-opacity='0.4'%3E%3Cpath d='M0 199V0h1v1.99L100 199h-1.12L1 4.22V199H0zM100 2h-.12l-1-2H100v2z'%3E%3C/path%3E%3C/g%3E%3C/svg%3E")`}}>
                <div>
                    <h1 className="display-5" style={{textAlign: 'center', color: 'white'}}>ВОТ, ЧТО МЫ МОЖЕМ ВАМ ПРЕДЛОЖИТЬ</h1>
                        <br/>
                        <Row>
                            <Col xs="12" sm="12" md="6" lg="6">
                                    <div className="card--container">
                                        <Row>
                                            <Col>
                                            <h3 style={{color: 'black'}}>Женский зал</h3>
                                            </Col>
                                        </Row>
                                        <Row style={{marginBottom: 12, marginTop: 55}}>
                                            <Col>
                                                <PricesOverlay categoryName="Женский зал" prices={props.prices.women}/>
                                            </Col>
                                        </Row>
                                    </div>
                            </Col>
                            <Col xs="12" sm="12" md="6" lg="6">
                                    <div className="card--container">
                                        <Row>
                                            <Col>
                                            <h3 style={{color: 'black'}}>Мужской зал</h3>
                                            </Col>
                                        </Row>
                                        <Row style={{marginBottom: 12, marginTop: 55}}>
                                            <Col>
                                                <PricesOverlay categoryName="Мужской зал" prices={props.prices.men}/>
                                            </Col>
                                        </Row>
                                    </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12" sm="12" md="6" lg="6">
                                    <div className="card--container">
                                        <Row>
                                            <Col>
                                            <h3 style={{color: 'black'}}>Акции</h3>
                                            </Col>
                                        </Row>
                                        <Row style={{marginBottom: 12, marginTop: 55}}>
                                            <Col>
                                                <PricesOverlay categoryName="Акции" prices={props.prices.sale}/>
                                            </Col>
                                        </Row>
                                    </div>
                            </Col>
                            <Col xs="12" sm="12" md="6" lg="6">
                                    <div className="card--container">
                                        <Row>
                                            <Col>
                                            <h3 style={{color: 'black'}}>Студентам</h3>
                                            </Col>
                                        </Row>
                                        <Row style={{marginBottom: 12, marginTop: 55}}>
                                            <Col>
                                                <PricesOverlay categoryName="Студентам" prices={props.prices.students}/>
                                            </Col>
                                        </Row>
                                    </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12" sm="12" md="6" lg="6">
                                    <div className="card--container">
                                        <Row>
                                            <Col>
                                            <h3 style={{color: 'black'}}>Процедуры ухода</h3>
                                            </Col>
                                        </Row>
                                        <Row style={{marginBottom: 12, marginTop: 55}}>
                                            <Col>
                                                <PricesOverlay categoryName="Процедуры ухода" prices={props.prices.care}/>
                                            </Col>
                                        </Row>
                                    </div>
                            </Col>
                            <Col xs="12" sm="12" md="6" lg="6">
                                    <div className="card--container">
                                        <Row>
                                            <Col>
                                            <h3 style={{color: 'black'}}>Ботокс</h3>
                                            </Col>
                                        </Row>
                                        <Row style={{marginBottom: 12, marginTop: 55}}>
                                            <Col>
                                                <PricesOverlay categoryName="Ботокс" prices={props.prices.botox}/>
                                            </Col>
                                        </Row>
                                    </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12" sm="12" md="6" lg="6">
                                    <div className="card--container">
                                        <Row>
                                            <Col>
                                            <h3 style={{color: 'black'}}>Окрашивание</h3>
                                            </Col>
                                        </Row>
                                        <Row style={{marginBottom: 12, marginTop: 55}}>
                                            <Col>
                                                <PricesOverlay categoryName="Окрашивание" prices={props.prices.dying}/>
                                            </Col>
                                        </Row>
                                    </div>
                            </Col>
                            <Col xs="12" sm="12" md="6" lg="6">
                                    <div className="card--container">
                                        <Row>
                                            <Col>
                                            <h3 style={{color: 'black'}}>Маникюр</h3>
                                            </Col>
                                        </Row>
                                        <Row style={{marginBottom: 12, marginTop: 55}}>
                                            <Col>
                                                <PricesOverlay categoryName="Маникюр" prices={props.prices.manicure}/>
                                            </Col>
                                        </Row>
                                    </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12" sm="12" md="6" lg="6">
                                    <div className="card--container">
                                        <Row>
                                            <Col>
                                            <h3 style={{color: 'black'}}>Педикюр</h3>
                                            </Col>
                                        </Row>
                                        <Row style={{marginBottom: 12, marginTop: 55}}>
                                            <Col>
                                                <PricesOverlay categoryName="Педикюр" prices={props.prices.pedicure}/>
                                            </Col>
                                        </Row>
                                    </div>
                            </Col>
                            <Col xs="12" sm="12" md="6" lg="6">
                                    <div className="card--container">
                                        <Row>
                                            <Col>
                                            <h3 style={{color: 'black'}}>Брови</h3>
                                            </Col>
                                        </Row>
                                        <Row style={{marginBottom: 12, marginTop: 55}}>
                                            <Col>
                                                <PricesOverlay categoryName="Брови" prices={props.prices.brows}/>
                                            </Col>
                                        </Row>
                                    </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12" sm="12" md="6" lg="6">
                                    <div className="card--container">
                                        <Row>
                                            <Col>
                                            <h3 style={{color: 'black'}}>Депиляция</h3>
                                            </Col>
                                        </Row>
                                        <Row style={{marginBottom: 12, marginTop: 55}}>
                                            <Col>
                                                <PricesOverlay categoryName="Депиляция" prices={props.prices.wax}/>
                                            </Col>
                                        </Row>
                                    </div>
                            </Col>
                            <Col xs="12" sm="12" md="6" lg="6">
                                    <div className="card--container">
                                        <Row>
                                            <Col>
                                            <h3 style={{color: 'black'}}>Подология</h3>
                                            </Col>
                                        </Row>
                                        <Row style={{marginBottom: 12, marginTop: 55}}>
                                            <Col>
                                                <PricesOverlay categoryName="Подология " prices={props.prices.podology}/>
                                            </Col>
                                        </Row>
                                    </div>
                            </Col>
                        </Row>
                </div>
            </Container>
            <Container id="team" fluid className=".container--fullpage" style={{flexDirection: 'column', marginTop: 50, marginBottom: 50}}>
                <Row>
                    <Col lg="6" md="6" xs="12" sm="12" style={{minHeight: '50vh', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}> 
                        <div style={{wordBreak: 'break-all', width: '85%'}}>
                           <h1 className="display-5 black" style={{wordBreak: 'break-word'}}>НАША КОМАНДА</h1>
                            <h4 className="lighter black" style={{wordBreak: "break-word"}}>Наши Мастера - профессионалы своего дела, которые непристанно учатся чему-то новому в своей области. Они постоянно посещают курсы повышения квалификации, профессиональные семинары и встречи, а также мастер-классы по нововведениям в области, чтобы выполнять работу эффективнее.</h4>
                        </div>
                        <br/>
                    </Col>
                    <Col lg="6" md="6" xs="12" sm="12" style={{minHeight: '50vh', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                       
                            <div className="worker--container">
                                <Row style={{width: '100%', padding: 12}}>
                                    <Col style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                                        <Image style={{alignSelf: 'center', width: 125, height: 125, borderRadius: 300}} src="https://images.yclients.com/masters/norm/9/9c/9cc1e603132b07c_20200201141536.png"></Image>
                                    </Col>
                                    <Col style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start',justifyContent: 'center'}}>
                                        <h4 style={{textAlign: 'left'}}>Николай</h4>
                                        <p style={{textAlign: 'left'}}>Парикмахер-универсал</p>
                                    </Col>
                                </Row>
                            </div>    
                        <br/>
                            <div className="worker--container">
                                <Row style={{width: '100%', padding: 12}}>
                                    <Col style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                                        <Image style={{alignSelf: 'center', width: 125, height: 125, borderRadius: 300}} src="https://images.yclients.com/masters/norm/9/98/9882e8d9ca14f3e_20200205233200.png"></Image>
                                    </Col>
                                    <Col style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start',justifyContent: 'center'}}>
                                        <h4 style={{textAlign: 'left'}}>Алексей</h4>
                                        <p style={{textAlign: 'left'}}>Парикмахер-универсал</p>
                                    </Col>
                                </Row>
                            </div>    
                        <br/>
                            <div className="worker--container">
                                <Row style={{width: '100%', padding: 12}}>
                                    <Col style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                                        <Image style={{alignSelf: 'center', width: 125, height: 125, borderRadius: 300}} src={process.env.PUBLIC_URL + "yulia.jpeg" }></Image>
                                    </Col>
                                    <Col style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start',justifyContent: 'center'}}>
                                        <h4 style={{textAlign: 'left'}}>Юлия</h4>
                                        <p style={{textAlign: 'left'}}>Парикмахер-универсал</p>
                                    </Col>
                                </Row>
                            </div>    
                        <br/>
                            <div className="worker--container">
                                <Row style={{width: '100%', padding: 12}}>
                                    <Col style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                                        <Image style={{alignSelf: 'center', width: 125, height: 125, borderRadius: 300}} src="https://images.yclients.com/masters/norm/3/35/35be2743a4546c3_20200201141559.png"></Image>
                                    </Col>
                                    <Col style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start',justifyContent: 'center'}}>
                                        <h4 style={{textAlign: 'left'}}>Татьяна</h4>
                                        <p style={{textAlign: 'left'}}>Мастер ногтевого сервиса, подолог</p>
                                    </Col>
                                </Row>
                            </div> 
                        <br/>
                        <div className="worker--container">
                                <Row style={{width: '100%', padding: 12}}>
                                    <Col style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                                        <Image style={{alignSelf: 'center', width: 125, height: 125, borderRadius: 300}} src="https://assets.yclients.com/masters/sm/f/fd/fdfa21d8e3d794f_20210815182143.jpeg"></Image>
                                    </Col>
                                    <Col style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start',justifyContent: 'center'}}>
                                        <h4 style={{textAlign: 'left'}}>Талия</h4>
                                        <p style={{textAlign: 'left'}}>Парикмахер-универсал</p>
                                    </Col>
                                </Row>
                            </div>    
                        <br/>
                        <div className="worker--container">
                                <Row style={{width: '100%', padding: 12}}>
                                    <Col style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                                        <Image style={{alignSelf: 'center', width: 125, height: 125, borderRadius: 300}} src="https://assets.yclients.com/masters/sm/d/d0/d0d9178cb8c3371_20210904193932.jpeg"></Image>
                                    </Col>
                                    <Col style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start',justifyContent: 'center'}}>
                                        <h4 style={{textAlign: 'left'}}>Тимур</h4>
                                        <p style={{textAlign: 'left'}}>Мастер ногтевого сервиса</p>
                                    </Col>
                                </Row>
                            </div>    
                    </Col>
                </Row>
            </Container>
            <Container id="contacts" fluid className="container--fullpage" style={{display: 'flex', minHeight: '100vh',width: '100vw', justifyContent: 'center', alignItems: 'center',padding: 30, backgroundColor: '#000000',
backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='199' viewBox='0 0 100 199'%3E%3Cg fill='%23ffffff' fill-opacity='0.4'%3E%3Cpath d='M0 199V0h1v1.99L100 199h-1.12L1 4.22V199H0zM100 2h-.12l-1-2H100v2z'%3E%3C/path%3E%3C/g%3E%3C/svg%3E")`}}>
                <div>
                    <h1 className="display-5" style={{textAlign: 'center', color: 'white'}}>КОНТАКТЫ</h1>
                        <br/>
                        <Row style={{backgroundColor: 'white', padding: 0, minHeight: 500, width: '80vw', borderRadius: 12}}>
                            <Col lg="6" md="6" xs="12" sm="12" style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', minHeight: 300}}>
                                    <div style={{display: 'flex', alignItems: 'flex-start', flexDirection: 'column', padding: 12}}>
                                            <h3 className="display-5">МОЙ СТИЛЬ</h3>
                                        <br/>
                                            <h6 style={{fontWeight: 'lighter'}}>+7 (906) 225-39-39</h6>
                                            <h6 style={{fontWeight: 'lighter'}}>mstyle.piter@gmail.com</h6>
                                            <h6 style={{fontWeight: 'lighter'}}>Переулок Каховского 12, БЦ "Пифагор"</h6> 
                                    </div>
                            </Col>
                            <Col lg="6" md="6" xs="12" sm="12" style={{padding: 0}}>
                                    <iframe width="100%" height="100%" id="gmap_canvas" style={isMobile ? ({borderBottomRightRadius: 12, borderBottomLeftRadius: 12}):({borderTopRightRadius: 12, borderBottomRightRadius: 12})} src="https://maps.google.com/maps?q=%D0%9F%D0%B5%D1%80%D0%B5%D1%83%D0%BB%D0%BE%D0%BA%20%D0%9A%D0%B0%D1%85%D0%BE%D0%B2%D1%81%D0%BA%D0%BE%D0%B3%D0%BE%2012&t=&z=15&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
                            </Col>
                        </Row>
                </div>
            </Container>
            <Container fluid className="footer--container">
                    <h5 style={{textAlign: 'center', color: 'white', fontSize: 16}}>Designed and Developed by Leonid Brown, 2021<br/>leorexwer@gmail.com</h5>
            </Container>
            </>
    )
}

export default Home