import {useState, useEffect} from 'react'
import { propTypes } from 'react-bootstrap/esm/Image';
import ReactLoading from 'react-loading';
import Fade from 'react-reveal/Fade';



export const Loading = (props) => {
    return(
        <>
            <div style={{width: '100vw', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'black'}}>
                <Fade>
                    <ReactLoading type={'spin'} color={'white'} height={'35px'} width={'35px'} />
                </Fade>
            </div>
        </>
    )
}

export default Loading